@use "../global" as g;

/* ==========================================================================
	price
========================================================================== */

.page__header--price {
	@include g.mq {
		background-color: g.$bg-color;
	}
}
.index {
	display: none;
	@include g.mq {
		display: block;
		margin-top: -6rem;
		background-color: g.$bg-color;
	}
	@include g.mq(sm) {
		margin-top: -4rem;
	}
	&__item {
		background-color: #fff;
		padding: 2.9rem 3.4rem 2.2rem 2rem;
		&:not(:last-of-type) {
			margin-bottom: 2.8rem;
		}
		&--economy {
			border-top: 7px solid #4a6066;
		}
		&--standard {
			border-top: 7px solid #e21096;
		}
		&--premium {
			border-top: 7px solid #bf910b;
		}
	}
	&__title {
		font-size: 2.4rem;
		font-weight: bold;
		margin-bottom: 1.1rem;
		@include g.mq(sm) {
			font-size: 2rem;
		}
	}
	&__desc {
		font-size: 1.4rem;
		letter-spacing: -0.02em;
	}
	&__price {
		font-size: 2.4rem;
		font-weight: bold;
		text-align: right;
		margin-bottom: 1.2rem;
		@include g.mq(sm) {
			font-size: 2rem;
		}
		span {
			font-size: 1.2rem;
		}
	}
	&__button {
		display: block;
		width: 19.3rem;
		font-size: 1rem;
		text-align: center;
		padding: 1rem 0;
		margin: 0 auto;
		transition: all 0.3s;
		&:hover {
			color: #fff;
		}
		&--economy {
			color: #4a6066;
			border: 1px solid #4a6066;
			&:hover {
				background-color: #4a6066;
			}
		}
		&--standard {
			color: #e21096;
			border: 1px solid #e21096;
			&:hover {
				background-color: #e21096;
			}
		}
		&--premium {
			color: #bf910b;
			border: 1px solid #bf910b;
			&:hover {
				background-color: #bf910b;
			}
		}
	}
}
// plan
.plan {
	&.plan--economy,
	&.plan--silver-personal,
	&.plan--silver-biz {
		padding-top: 0;
		@include g.mq {
			padding-top: 6rem;
		}
		@include g.mq(sm) {
			padding-top: 4rem;
		}
	}
	._bg--gray {
		background-color: g.$bg-color;
	}
	._bg--white {
		background-color: #fff;
	}
	&:last-of-type {
		@include g.mq {
			padding-bottom: 0;
		}
	}
	&__inner {
		@include g.mq {
			padding: 3rem 2rem 4.6rem;
			&--economy,
			&--premium {
				background-color: g.$bg-color;
			}
			&--economy {
				border-top: 7px solid #4a6066;
			}
			&--standard {
				background-color: #fff;
				border-top: 7px solid #e21096;
			}
			&--premium {
				border-top: 7px solid #bf910b;
			}
		}
	}
	&__title {
		font-size: 2.4rem;
		font-weight: bold;
		margin-bottom: 1.5rem;
		@include g.mq(sm) {
			font-size: 2rem;
		}
	}
	&__desc {
		font-size: 1.4rem;
		padding: 0.85rem 2.1rem;
		margin-bottom: 2.1rem;
		color: #fff;
		@include g.mq {
			padding: 0 0 2.2rem;
			color: g.$main-text-color;
			border-bottom: 1px solid #4A6066;
			font-weight: bold;
		}
		&--economy {
			background-color: #4a6066;
			@include g.mq {
					background-color: transparent;
			}
		}
		&--standard {
			background-color: #e21096;
			@include g.mq {
				background-color: transparent;
			}
		}
		&--premium {
			background-color: #bf910b;
			@include g.mq {
				background-color: transparent;
			}
		}
	}
	&__sub-desc {
		font-size: 1.4rem;
		padding: 0 2.1rem;
		margin-bottom: 5.3rem;
		@include g.mq {
			margin-bottom: 2.7rem;
			font-size: 1.2rem;
			padding: 0.9rem 0.8rem;
		}
		span {
			color: #e10f96;
		}
	}
	&__detail {
		margin-bottom: 4.5rem;
		@include g.mq {
			margin-bottom: 0;
			padding: 1.5rem 0 0.3rem;
			border-top: 1px solid #E6E6E6;
		}
		&__title {
			margin-bottom: 0.4rem;
			&._size--l {
				font-size: 2.1rem;
			}
			&._size--s {
				font-size: 1.8rem;
			}
			&._size--l,
			&._size--s {
				@include g.mq {
					font-size: 1.4rem;
					font-weight: bold;
				}
			}
			&._text--red {
				color: #e10f96;
			}
			&--span {
				font-size: 1.4rem;
				@include g.mq {
					font-size: 1rem;
					display: block;
					font-weight: normal;
					padding-top: 0.8rem;
				}
			}
		}
		&__box {
			padding: 1.2rem 0;
			border-radius: 10px;
			display: flex;
			align-items: center;
			height: 12.6rem;
			@include g.mq {
				flex-direction: column;
				height: auto;
				padding: 0;
			}
			&._bg--transparent {
				background-color: transparent;
				border: 1px solid #4a5e65;
				@include g.mq {
					border: none;
				}
			}
			& + .plan__detail__box {
				margin-top: 3.8rem;
				@include g.mq {
					margin-top: 0;
				}
			}
			&._align-center {
				justify-content: center;
			}
		}
		&__inner {
			text-align: center;
			@include g.mq {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			&._divide--2 {
				width: 50%;
				@include g.mq {
					width: 100%;
					max-width: 43rem;
					padding: 1.2rem 0;
					margin: 0 auto;
				}
			}
			&._divide--3 {
				width: 33.33%;
				padding-top: 1.2rem;
				align-self: start;
				@include g.mq {
					width: 100%;
					max-width: 43rem;
					padding-bottom: 1.2rem;
					margin: 0 auto;
				}
			}
			&._on--left._bd--black {
				border-right: 1px solid #4a5e65;
				@include g.mq {
					border-right: none;
				}
			}
			&._on--left._bd--gray {
				border-right: 1px solid #050707;
				@include g.mq {
					border-right: none;
				}
			}
		}
		&__text {
			font-size: 3.6rem;
			font-weight: bold;
			@include g.mq(lg) {
				font-size: 3.2rem;
			}
			@include g.mq {
				font-size: 1.8rem;
				text-align: right;
				line-height: 1;
				flex-shrink: 0;
			}
			&--span {
				font-size: 1.4rem;
				font-weight: bold;
				padding-left: 2.4rem;
				@include g.mq {
					padding-left: 0;
				}
				&._within {
					@include g.mq {
						font-size: 1.8rem;
					}
				}
				&:not(._within) {
					@include g.mq {
						display: block;
						font-size: 1rem;
						font-weight: normal;
						line-height: 1.5;
					}
				}
			}
			&._option {
				@include g.mq {
					text-align: left;
					font-size: 1.4rem;
					font-weight: normal;
				}
			}
			&._text--red {
				color: #e10f96;
			}
		}
		&__sub-text {
			font-size: 1.4rem;
			font-weight: bold;
			&:not(._text--red):not(._option) {
				@include g.mq {
					font-weight: normal;
				}
			}
			&:not(._option) {
				@include g.mq {
					text-align: left;
					margin-right: 1rem;
				}
			}
			&._option {
				@include g.mq {
					font-size: 1.8rem;
				}
			}
			&._text--red {
				color: #e10f96;
			}
			&._text--gray {
				color: #4a5e65;
				@include g.mq {
					color: g.$main-text-color;
				}
			}
		}
		&__note {
			font-size: 1rem;
			@include g.mq {
				text-align: right;
			}
			&._on--under {
				text-align: right;
				margin: 0.5rem 0 1.2rem;
			}
		}
		&__bottom {
			text-align: right;
			@include g.mq {
				text-align: center;
			}
		}
		&__link {
			display: inline-block;
			font-size: 1.4rem;
			line-height: 1;
			padding: 1.6rem 0;
			background-color: transparent;
			border-radius: 3px;
			width: 25rem;
			text-align: center;
			transition: all 0.3s;
			@include g.mq {
				width: 100%;
				margin-top: 3rem;
			}
			&:hover {
				color: #fff;
			}
			&--economy {
				color: #4a6066;
				border: 1px solid #4a6066;
				&:hover {
					background-color: #4a6066;
				}
			}
			&--standard {
				color: #e21096;
				border: 1px solid #e21096;
				&:hover {
					background-color: #e21096;
				}
			}
			&--premium {
				color: #bf910b;
				border: 1px solid #bf910b;
				&:hover {
					background-color: #bf910b;
				}
			}
		}
	}
	&--standard,
	&--gold-personal,
	&--gold-biz {
		background-color: g.$bg-color;
	}
}