@use "../global" as g;

/* ==========================================================================
    inquiry
========================================================================== */

.inquiry {
	&--bg-gray {
		background-color: g.$bg-color;
	}
	&--bg-white {
		background-color: #fff;
	}
	&__title {
		padding-bottom: 3.2rem;
		border-bottom: 1px solid g.$main-text-color;
		margin-bottom: 4rem;
		@include g.mq {
			margin-bottom: 2.5rem;
		}
		&._align-center {
			text-align: center;
		}
	}
	&__content {
		width: 77.4%;
		margin: 0 auto;
		@include g.mq {
			width: 100%;
		}
	}
	&__desc {
		font-size: 2.8rem;
		font-weight: bold;
		margin-bottom: 4.3rem;
		text-align: center;
		@include g.mq {
			font-size: 1.8rem;
			text-align: left;
		}
	}
	&__button {
		display: block;
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1;
		padding: 2.3rem 0;
		border: 1px solid #483519;
		text-align: center;
		transition: all 0.3s;
		&--inquiry {
			width: 43.8%;
			color: #483519;
			@include g.mq {
				width: 100%;
			}
			&:hover {
				background-color: rgba(72, 53, 25, 0.1);
			}
		}
		&--consultation {
			width: 51.4%;
			color: #fff;
			background-color: #483519;
			@include g.mq {
				width: 100%;
				margin-bottom: 3.2rem;
			}
			&:hover {
				opacity: 0.9;
			}
			span {
				position: relative;
				padding-left: 4.9rem;
				&::before {
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					content: "";
					width: 3.5rem;
					height: 2.6rem;
					background-image: url('../images/common/icon_arrow_inquiry.svg');
				}
			}
		}
		&._bdrs {
			border-radius: 10px;
		}
		&__wrap {
			display: flex;
			justify-content: space-between;
			margin-bottom: 5rem;
			@include g.mq {
				flex-direction: column-reverse;
				margin-bottom: 3rem;
			}
		}
	}
	&__bottom {
		font-size: 1.4rem;
		@include g.mq {
			font-size: 1.2rem;
		}
	}
	&__note {
		margin-bottom: 3rem;
	}
	&__link {
		&__item {
			text-align: right;
			&:not(:last-of-type) {
				margin-bottom: 1.7rem;
			}
		}
		&__text {
			color: #1700B9;
			transition: all 0.3s;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
