@use "../global" as g;

/* ==========================================================================
    category
========================================================================== */

.category {
	position: relative;
  padding: 3.2rem 1.6rem;
  color: #333;
	&__inner {
		max-width: 1440px;
		margin: 0 auto;
	}
	&__item {
		width: auto;
	}
	&__link {
		display: flex;
		align-items: center;
	}
	&__icon {
		width: 24px;
		height: 24px;
		margin-right: 0.8rem;
	}
	&__name {
		white-space: nowrap;
	}
	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: -0.5rem;
	}
	.swiper-pagination-bullet {
		width: 6px;
		height: 6px;
		background-color: #ccc;
		border-radius: 50%;
		transition: background-color 0.6s ease;
		opacity: 1;
		&-active,
		&:hover {
			border-color: g.$link-color;
			background-color: g.$link-color;
		}
	}
	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, 
	.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 8px;
	}
}
