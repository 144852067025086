@use 'variables' as v;

// ブレークポイント
$breakpoints: (
  'xs': 'screen and (max-width: 359px)',
  'sm': 'screen and (max-width: 479px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 979px)',
  'xl': 'screen and (max-width: 1279px)',
);

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// アンダーライン
@mixin under-black($bottom: -18px) {
  span {
    display: inline-block;
    position: relative;
    &::after {
      position: absolute;
      bottom: $bottom;
      left: 0;
      content: "";
      width: 100%;
      height: 3px;
      background-color: v.$main-text-color;
    }
  }
}

@mixin under-color($bottom: 0, $height, $bg-color, $opacity: 1) {
  span {
    display: inline-block;
    position: relative;
    z-index: 1;
    &::after {
      position: absolute;
      bottom: $bottom;
      left: 0;
      content: "";
      width: 100%;
      height: $height;
      background-color: $bg-color;
      z-index: -1;
      opacity: $opacity;
    }
  }
}