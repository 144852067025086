@use "../global" as g;

/* ==========================================================================
    メインビジュアル
========================================================================== */
.hero {
	margin-top: -8rem;
}
// home page__america共通
.all,
.home,
.page__america {
	.hero {
		background-image: url("../images/top/hero-image1920-pc.jpg");
		background-repeat: no-repeat;
		background-position: left 45% bottom 19%;
		background-size: cover;
		height: 44.2rem;
		padding-top: 10rem;
		@include g.mq(lg) {
			background-position: center bottom 19%;
		}
		@include g.mq {
			background-position: left 55% bottom 11.5%;
		}
		&__inner {
			position: relative;
			height: 100%;
		}
		&__person {
			position: absolute;
			right: 28%;
			bottom: -2rem;
			width: 34.8rem;
			height: 34.8rem;
			@include g.mq {
				right: -3rem;
				width: 27rem;
				height: 27rem;
			}
			@include g.mq(sm) {
				width: 22rem;
				height: 22rem;
			}
		}
	}
}

// home hero__copy
.home {
	.hero {
		&__copy {
			width: 40%;
			padding-top: 6rem;
			letter-spacing: -0.25px;
			@include g.mq(lg) {
				padding-top: 2rem;
			}
			@include g.mq {
				width: 60%;
			}
			@include g.mq(sm) {
				width: 100%;
			}
			&__title {
				margin-bottom: 1.6rem;
				font-weight: 700;
				font-size: 3.6rem;
				line-height: 1.2;
				white-space: nowrap;
				@include g.mq(xl) {
					font-size: 2.8rem;
				}
				@include g.mq(lg) {
					font-size: 2.6rem;
				}
				@include g.mq {
					font-size: 2.2rem;
				}
			}
			&__text {
				font-weight: 400;
				font-size: 1.8rem;
				line-height: 1.6;
				@include g.mq(xl) {
					font-size: 1.6rem;
				}
				@include g.mq(lg) {
					font-size: 1.4rem;
				}
				@include g.mq {
					font-size: 1.2rem;
				}
			}
		}
	}
}

// page__america hero__copy
.page__america {
	.hero {
		&__copy {
			width: 45%;
			max-width: 500px;
			letter-spacing: -0.25px;
			padding-top: 2rem;
			@include g.mq(lg) {
				width: 40%;
				padding-top: 2rem;
			}
			@include g.mq {
				width: 65%;
				padding-top: 0;
			}
			@include g.mq(sm) {
				width: 100%;
			}
			&__title {
				font-size: 3.6rem;
				font-weight: 700;
				line-height: 1.2;
				margin-bottom: 1.6rem;
				@include g.mq(xl) {
					font-size: 3.2rem;
				}
				@include g.mq(lg) {
					font-size: 2.4rem;
				}
				@include g.mq {
					font-size: 2rem;
				}
			}
			&__text {
				width: 85%;
				font-size: 1.4rem;
				line-height: 1.6;
				font-weight: 400;
				@include g.mq(lg) {
					width: 65%;
				}
				@include g.mq {
					font-size: 1.2rem;
					width: 77%;
				}
				@include g.mq(sm) {
					width: 53%;
				}
			}
		}
	}
}

// page__for-experts, page__for-question, page__for-overview, page__countries共通
.page__for-experts,
.page__for-question,
.page__for-overview,
.page__countries {
	.hero {
		position: relative;
		padding: 10rem 0;
		@include g.mq(sm) {
			padding-bottom: 9rem;
		}
		&::after {
			display: block;
			position: absolute;
			content: "";
			height: 6.4rem;
			right: 0;
			bottom: 0;
			left: 0;
			top: auto;
			background: rgba(0, 0, 0, 0.4);
			z-index: 10;
		}
		&__slide {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&__image {
				background-repeat: no-repeat;
				background-position: center top;
				background-size: cover;
			}
		}
		&__body {
			&__inner {
				display: flex;
				justify-content: space-between;
				align-items: center;
				@include g.mq(lg) {
					flex-direction: column;
					padding-top: 5rem;
				}
			}
		}
		&__copy {
			width: 55%;
			color: #fff;
			margin-right: 8rem;
			z-index: 10;
			@include g.mq(lg) {
				width: 100%;
				max-width: 60rem;
				margin-right: 0;
			}
			@include g.mq {
				max-width: 50rem;
			}
			&__title {
				font-size: 4.8rem;
				line-height: 1.25;
				font-weight: 700;
				margin-bottom: 1.6rem;
				@include g.mq(lg) {
					font-size: 4rem;
				}
				@include g.mq {
					font-size: 3.2rem;
				}
				@include g.mq(sm) {
					font-size: 2.4rem;
					line-height: 1.16;
					margin-bottom: 0.8rem;
				}
			}
			&__text {
				font-size: 2rem;
				line-height: 1.2;
				@include g.mq {
					font-size: 1.6rem;
				}
				@include g.mq(sm) {
					font-size: 1.4rem;
					line-height: 1.43;
				}
			}
		}
		&__logo {
			position: absolute;
			bottom: 0;
			height: 6.4rem;
			width: 100%;
			z-index: 10;
			&__inner {
				display: flex;
				align-items: center;
				height: 100%;
			}
			&__image {
				&:not(:last-of-type) {
					margin-right: 4rem;
					@include g.mq {
						margin-right: 2rem;
					}
					@include g.mq(sm) {
						margin-right: 1rem;
					}
				}
			}
		}
	}
}

// page__for-question, page__for-overview共通
.page__for-question,
.page__for-overview {
	.hero {
		&__slide {
			&__image {
				&:nth-of-type(1) {
					background-image: url('../images/for-question/bg_qa_hero_01.jpg');
					background-position: left 30% top;
					@include g.mq {
						background-image: url('../images/for-question/bg_qa_hero_sp_01.png');
						background-position: center top;
					}
				}
				&:nth-of-type(2) {
					background-image: url('../images/for-question/bg_qa_hero_02.jpg');
					@include g.mq {
						background-image: url('../images/for-question/bg_qa_hero_sp_02.png');
					}
				}
				&:nth-of-type(3) {
					background-image: url('../images/for-question/bg_qa_hero_03.jpg');
					background-position: left 10% top ;
					@include g.mq {
						background-image: url('../images/for-question/bg_qa_hero_sp_03.png');
					}
				}
			}
		}
	}
}

// page__for-experts専用
.page__for-experts {
	.hero {
		&__slide {
			&__image {
				&:nth-of-type(1) {
					background-image: url('../images/for-experts/bg_hero_01.jpg');
					background-position: left 30% top;
					@include g.mq {
						background-image: url('../images/for-experts/bg_hero_sp_01.png');
						background-position: center top;
					}
				}
				&:nth-of-type(2) {
					background-image: url('../images/for-experts/bg_hero_02.jpg');
					@include g.mq {
						background-image: url('../images/for-experts/bg_hero_sp_02.png');
					}
				}
				&:nth-of-type(3) {
					background-image: url('../images/for-experts/bg_hero_03.jpg');
					background-position: left 10% top ;
					@include g.mq {
						background-image: url('../images/for-experts/bg_hero_sp_03.png');
					}
				}
				&:nth-of-type(4) {
					background-image: url('../images/for-experts/bg_hero_04.jpg');
					background-position: center top ;
					@include g.mq {
						background-image: url('../images/for-experts/bg_hero_sp_04.png');
					}
				}
				&:nth-of-type(5) {
					background-image: url('../images/for-experts/bg_hero_05.jpg');
					background-position: left 35% top ;
					@include g.mq {
						background-image: url('../images/for-experts/bg_hero_sp_05.png');
					}
				}
				&:nth-of-type(6) {
					background-image: url('../images/for-experts/bg_hero_06.jpg');
					background-position: left 20% top ;
					@include g.mq {
						background-image: url('../images/for-experts/bg_hero_sp_06.png');
					}
				}
				&:nth-of-type(7) {
					background-image: url('../images/for-experts/bg_hero_07.jpg');
					background-position: left 30% top ;
					@include g.mq {
						background-image: url('../images/for-experts/bg_hero_sp_07.png');
					}
				}
			}
		}
	}
}

// page__countries専用
.page__countries {
	.hero {
		&__slide {
			&__image {
				&:nth-of-type(1) {
					background-image: url('../images/countries/bg_countries_hero_01.jpg');
					background-position: left 30% top;
					@include g.mq {
						background-image: url('../images/countries/bg_countries_hero_sp_01.jpg');
						background-position: center top;
					}
				}
				&:nth-of-type(2) {
					background-image: url('../images/countries/bg_countries_hero_02.jpg');
					@include g.mq {
						background-image: url('../images/countries/bg_countries_hero_sp_02.png');
					}
				}
				&:nth-of-type(3) {
					background-image: url('../images/countries/bg_countries_hero_03.jpg');
					background-position: left 10% top ;
					@include g.mq {
						background-image: url('../images/countries/bg_countries_hero_sp_03.png');
					}
				}
			}
		}
	}
}

/* second-formを挿入した場合 */
.include-second-form {
	.hero {
		height: 68rem;
		@include g.mq {
			height: 87.3rem;
		}
		&__person {
			@include g.mq {
				display: none;
			}
		}
	}
}