@use "../global" as g;

/* ==========================================================================
		common
========================================================================== */

.container {
	width: 90%;
	max-width: g.$max-width;
	margin: 0 auto;
}

.contents {
	padding-bottom: 9rem;
	@include g.mq {
		padding-bottom: 6rem;
	}
	@include g.mq(sm) {
		padding-bottom: 4rem;
	}
}

.page__header {
	padding: 7rem 0 5rem;
	@include g.mq {
		padding: 5rem 0 3.5rem;
	}
	@include g.mq(sm) {
		padding: 3rem 0 2rem;
	}
}

.page__title {
	text-align: center;
	font-size: 4.8rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.25px;
	@include g.mq {
		font-size: 3.8rem;
	}
	@include g.mq(sm) {
		font-size: 3rem;
	}
	&.has-sub-title {
		margin-bottom: 1.5rem;
		@include g.mq {
			margin-bottom: 1rem;
		}
	}
}

.page__sub-title {
	font-size: 1.4rem;
	text-align: center;
}

.main-section {
	&:not(:last-of-type) {
		padding: 9rem 0;
		@include g.mq {
			padding: 6rem 0;
		}
		@include g.mq(sm) {
			padding: 4rem 0;
		}
	}
	&:last-of-type {
		padding: 9rem 0 0;
		@include g.mq {
			padding: 6rem 0 0;
		}
		@include g.mq(sm) {
			padding: 4rem 0 0;
		}
	}
}

.section__title {
	font-size: 4rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.25px;
	@include g.mq {
		font-size: 3.2rem;
		text-align: center;
	}
	@include g.mq(sm) {
		font-size: 2.4rem;
	}
	&._align-center {
		text-align: center;
	}
}

.section__sub-title {
	font-size: 1.8rem;
	font-weight: bold;
	@include g.mq(sm) {
		font-size: 1.6rem;
	}
}

.section__desc {
	line-height: 2.3;
	@include g.mq {
		text-align: left;
		font-size: 1.4rem;
		line-height: 2;
		letter-spacing: -0.02em;
	}
}

.section__text {
	font-size: 1.4rem;
	line-height: 2;
}

.section__footer {
	height: 9rem;
	margin-bottom: -9rem;
	background: linear-gradient(180deg, #f7f7f8 0, rgba(255, 255, 255, 0));
	transform: matrix(1, 0, 0, -1, 0, 0);
	@include g.mq {
		height: 6rem;
		margin-bottom: -6rem;
	}
	@include g.mq(sm) {
		height: 4rem;
		margin-bottom: -4rem;
	}
}

/* ボタン */
.button--common01 {
	display: block;
	text-align: center;
	font-size: 1.2rem;
	font-weight: bold;
	border: 1px solid #483519;
	border-radius: 3px;
	padding: 1.2rem 0;
	color: #483519;
	transition: all 0.3s;
	&:hover {
		background-color: rgba(72, 53, 25, 0.1);
	}
}

.button--common02 {
	display: block;
  padding: 1.2rem 0;
  border: 1px solid #483519;
  background-color: #483519;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  transition: all 0.3s;
	&:hover {
		background-color:rgba(255,255,255,0.1);
		color: #483519;
	}
}

.button--more {
	display: block;
	position: relative;
	width: 14.8rem;
	margin-left: auto;
	padding: 1rem 1.3rem;
	border: 1px solid #333;
	font-weight: bold;
	font-size: 1.2rem;
	&::after {
		position: absolute;
		top: 50%;
		right: 12px;
		transform: translateY(-50%);
		content: "";
		background-image: url('../images/america/icon_arrow-right.svg');
		width: 22.5px;
		height: 13.5px;
	}
}

.button--apply,
.button--list {
	display: block;
	border: 1px solid #483519;
	border-radius: 3px;
	background-color: #483519;
	color: #fff;
	font-weight: bold;
	font-size: 1.2rem;
	text-align: center;
	transition: all 0.3s;
	padding: 0;
	width: 24rem;
	span {
		position: relative;
		display: inline-block;
		font-size: 1.4rem;
		font-weight: bold;
		line-height: 1;
		color: #fff;
		padding: 1.3rem 0 1.2rem 2.7rem;
		&::before {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			display: inline-block;
			content: "";
		}
	}
	&:hover {
		span {
			color: #483519;
		}
	}
}

.button--apply {
	span::before {
		background-image: url('../images/america/icon_thumbs-up-white.svg');
		width: 1.81rem;
		height: 1.45rem;
	}
	&:hover {
		span::before {
			background-image: url('../images/america/icon_thumbs-up-black.svg');
		}
	}
}

.button--list {
	span::before {
		background-image: url('../images/america/icon_hamburger-white.svg');
		width: 1.2rem;
		height: 1rem;
	}
	&:hover {
		span::before {
			background-image: url('../images/america/icon_hamburger-black.svg');
		}
	}
}