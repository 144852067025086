@use "../global" as g;

/* ==========================================================================
		base
========================================================================== */
html {
  font-size: 62.5%;
}

body {
  position: relative;
  color: #333;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "ゴシックMB101 M", "メイリオ", "Meiryo", Arial, Helvetica, sans-serif, Verdana;
}

html, body {
  overflow-x: hidden;
}

*:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover {
  color: g.$link-color;
}