@use "../global" as g;

/* ==========================================================================
	america
========================================================================== */

.main-section--region {
	position: relative;
	padding: 7.5rem 0 6rem !important;
	&:not(.what-is-possible):not(.pride)::before {
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translateX(-50%) rotate(90deg);
		content: "";
		width: 60px;
		height: 3px;
		background-color: g.$main-text-color;
	}
}
.section__header--region {
	text-align: center;
	margin-bottom: 4.4rem;
}
.country-name {
	font-size: 1.4rem;
	font-weight: bold;
	margin-bottom: 5.6rem;
	@include g.under-black();
}
.section__title--region {
	margin-bottom: 2.2rem;
	line-height: 1.5;
}
.section__content--region {
	margin-bottom: 6rem;
	&__inner {
		width: 81.7%;
		margin: 0 auto;
		@include g.mq {
			width: 100%;
		}
	}
}
.america__button {
	margin: 0 auto;
}	
.table {
	display: table;
	.table__cell {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}
	.table__header {
		display: table-header-group;
	}
	.table__row {
		display: table-row;
	}
}
.what-is-possible {
	padding-top: 5rem;
	&__content {
		margin-bottom: 10rem;
		position: relative;
	}
	&__slide {
		width: 33rem;
		overflow: visible;
	}
	&__item {
		width: 100%;
		padding: 3.5rem 3rem;
		background-color: g.$bg-color;
		z-index: -2;
		&__header {
			display: flex;
			margin-bottom: 2.5rem;
		}
		&__icon {
			margin-right: 0.5rem;
			flex-shrink: 0;
		}
		&__title {
			font-weight: bold;
			line-height: 1.1;
			display: flex;
			flex-direction: column;
			&--sub,
			&--main {
				display: block;
			}
			&--sub {
				font-size: 1.4rem;
			}
			&--main {
				font-size: 4.2rem;
				@include g.under-color(0, 13px, #FF99D8);
				&._fz-s {
					font-size: 2.4rem;
					letter-spacing: -0.05em;
					white-space: nowrap;
					margin-top: auto;
				}
			}
		}
		&__text {
			margin-bottom: 2.3rem;
		}
	}
	.swiper-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: -5rem;
	}
	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		background-color: #ccc;
		border-radius: 50%;
		transition: background-color 0.6s ease;
		opacity: 1;
		&-active,
		&:hover {
			background-color: #C0B06A;
			border-color: #C0B06A;
		}
	}
	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 8px;
	}
}
.feature {
	background-color: g.$bg-color;
	&__content {
		padding: 6.5rem 4%;
		background-color: #fff;
		@include g.mq {
			padding: 4rem 4%;
		}
	}
	&__list {
		display: flex;
		@include g.mq {
			flex-direction: column;
		}
	}
	&__item {
		padding: 0 5%;
		width: calc(100% / 3);
		@include g.mq {
			width: 100%;
		}
		&:not(:last-of-type) {
			border-right: 1px solid g.$main-text-color;
			@include g.mq {
				border-right: none;
				border-bottom: 1px solid g.$main-text-color;
				margin-bottom: 3rem;
				padding-bottom: 3rem;
			}
		}
		&__icon {
			margin-bottom: 3rem;
			text-align: center;
			@include g.mq {
				width: 6.1rem;
				height: 6.1rem;
				margin: 0 auto 2rem;
			}
		}
		&__title {
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1;
			margin-bottom: 3rem;
			text-align: center;
			@include g.under-color(0, 13px, #FF99D8);
		}
		&__desc {
			margin-bottom: 1rem;
			white-space: break-spaces;
			@include g.mq {
				white-space: normal;
			}
		}
	}
}
.pride {
	background-color: #000;
	color: #fff;
	position: relative;
	&::before {
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translateX(-50%) rotate(90deg);
		content: "";
		width: 60px;
		height: 3px;
		background-color: #fff !important;
	}
	.country-name {
		span::after {
			background-color: #fff;
		}
	}

	&__content {
		display: flex;
		border: 5px solid #0779FF;
		@include g.mq {
			flex-direction: column-reverse;
			margin-bottom: 0;
		}
		&__text-area {
			width: 44%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem 0;
			@include g.mq {
				width: 100%;
				padding: 2rem 0;
			}
		}
		&__image {
			width: 56%;
			@include g.mq {
				width: 100%;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&__text {
			width: 85.6%;
			font-size: 1.6rem;
			line-height: 2;
			margin: 0 auto;
			@include g.mq {
				font-size: 1.4rem;
			}
		}
	}

}
.human-resource {
	&__content {
		display: flex;
		justify-content: space-between;
		width: 87.2%;
		margin: 0 auto 6rem;
		@include g.mq {
			width: 100%;
			flex-direction: column;
		}
		&__item {
			width: 47.8%;
			@include g.mq {
				width: 100%;
			}
			&--left {
				@include g.mq {
					margin-bottom: 5rem;
				}
			}
		}
		&__image {
			margin-bottom: 2.4rem;
		}
		&__title {
			font-size: 2.4rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 2rem;
			@include g.under-color(0, 13px, #FFFF98);
		}
		&__text {
			padding: 0 8%;
		}
	}
	&__figure {
		text-align: center;
		img {
			width: 100%;
		}
	}
}
.reasonable-reason {
	background-color: g.$bg-color;
	&__item {
		display: flex;
		background-color: #fff;
		padding: 4.5rem 0;
		@include g.mq {
			flex-direction: column;
			padding: 3rem 8%;
		}
		&:not(:last-of-type) {
			margin-bottom: 6.2rem;
		}
		&__header {
			width: 42%;
			padding: 1rem 2% 0 8%;
			border-right: 1px solid #C4C4C4;
			flex-shrink: 0;
			@include g.mq(lg) {
				padding: 1rem 2% 0 5%;
			}
			@include g.mq {
				width: 100%;
				padding: 0;
				border-right: none;
			}
		}
		&__index {
			font-size: 2rem;
			font-weight: bold;
			line-height: 1;
			margin-bottom: 1.3rem;
		}
		&__title {
			font-size: 2.7rem;
			font-weight: bold;
			margin-bottom: 2.2rem;
			@include g.mq(lg) {
				font-size: 2.4rem;
			}
			@include g.mq {
				margin-bottom: 1rem;
			}
			@include g.mq(sm) {
				font-size: 2.2rem;
			}
		}
		&__desc {
			font-size: 1.2rem;
			@include g.under-color(-2px, 10px, #FFFF98);
		}
		&__body {
			width: 58%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 2;
			@include g.mq {
				width: 100%;
				padding-top: 3rem;
			}
		}
		&__text {
			line-height: 2;
			width: 70.7%;
			@include g.mq {
				width: 100%;
				font-size: 1.4rem;
			}
		}
	}
}
.great-value,
.comparison {
	.section__title--region {
		@include g.under-color(0, 23px, #1492E6, 0.13);
	}
}
.great-value {
	background-image: linear-gradient(#fff, g.$bg-color);
	&__content {
		margin: 0;
	}
	&__price {
		margin-bottom: 4.6rem;
		&__header {
			display: flex;
			justify-content: space-around;
			margin-bottom: 2.6rem;
			@include g.mq {
				justify-content: space-between;
			}
		}
		&__title-area {
			display: flex;
			align-items: center;
			@include g.mq {
				flex-direction: column;
			}
			&--market-price {
				@include g.mq {
					width: 50%;
				}
			}
			&--our-price {
				@include g.mq {
					width: 40%;
				}
			}
		}
		&__icon {
			margin-right: 2.5rem;
			width: 8rem;
			height: 8rem;
			flex-shrink: 0;
			@include g.mq(lg) {
				width: 5.6rem;
				height: 5.6rem;
			}
			@include g.mq {
				margin: 0 auto 1.3rem;
				
			}
		}
		&__title {
			font-size: 2.7rem;
			font-weight: bold;
			@include g.mq(lg) {
				font-size: 2rem;
			}
			@include g.mq {
				font-size: 1.4rem;
				text-align: center;
			}
		}
		&__item {
			&--market-price {
				@include g.mq {
					margin-bottom: 5rem;
				}
			}
		}
	}
	&__table {
		width: 100%;
		&__header {
			@include g.mq {
				height: 3.5rem;
				background-color: #3E3E3E;
				color: #fff;
				font-weight: bold;
			}
		}
		&__row {
			font-weight: bold;
			height: 4.5rem;
			width: 100%;
			@include g.mq {
				height: 3.5rem;
			}
			&:nth-of-type(odd) {
				background-color: rgba(20, 146, 230, 0.13);
			}
		}
		&__per-day {
			font-size: 1.4rem;
			font-weight: bold;
			width: 15.6%;
			@include g.mq {
				width: 25%;
				margin-left: auto;
			}
		}
		&__fee,
		&__language {
			font-size: 1.8rem;
			@include g.mq {
				font-size: 1.4rem;
			}
		}
		&__language {
			width: 30.2%;
			text-align: center;
			@include g.mq {
				width: 30%;
				font-weight: bold;
				min-width: 7.5rem;
			}
		}
		&__fee {
			width: 27.1%;
			text-align: center;
			@include g.mq {
				width: 35%;
			}
		}
	}
	&__copy {
		font-size: 3.6rem;
		font-weight: bold;
		line-height: 1.33;
		text-align: center;
		margin-bottom: 1.7rem;
		@include g.under-color(0, 17px, #FFFF98);
		@include g.mq {
			font-size: 2.4rem;
		}
	}
	&__note {
		font-size: 1.4rem;
		text-align: right;
	}
}
.comparison {
	&__content {
		&__header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 2.6rem;
			@include g.mq {
				margin-bottom: 1.5rem;
			}
		}
		&__title {
			font-size: 2.7rem;
			font-weight: bold;
			text-align: center;
			width: 35%;
			@include g.mq(lg) {
				font-size: 2rem;
			}
			@include g.mq {
				font-size: 1.4rem;
			}
			&:nth-of-type(1) {
				@include g.mq(xl) {
					width: 45%;
				}
				@include g.mq(sm) {
					width: 59%;
				}
			}
		}
	}
	&__table {
		width: 100%;
		&__row {
			font-weight: bold;
			height: 4.5rem;
			text-align: center;
			&:nth-of-type(odd) {
				background-color: g.$bg-color;
			}
			@include g.mq {
				height: 3.5rem;
			}
		}
		&__cell {
			&:nth-of-type(1),
			&:nth-of-type(3) {
				font-size: 2rem;
				width: 35%;
				@include g.mq {
					font-size: 1.4rem;
				}
				@include g.mq(sm) {
					font-size: 1.2rem;
					width: 37%;
				}
			}
			&:nth-of-type(2) {
				font-size: 2.2rem;
				width: 30%;
				@include g.mq {
					font-size: 1.4rem;
				}
				@include g.mq(sm) {
					font-size: 1.2rem;
					width: 26%;
				}
			}
		}
		// &__dt {
		// 	font-size: 2.2rem;
		// 	font-weight: bold;
		// 	width: 28%;
		// 	min-width: 11rem;
		// }
		// &__dd {
		// 	font-size: 2rem;
		// 	width: 28%;
		// 	min-width: 19.3rem;
		// }
	}
}
.support {
	background-color: g.$bg-color;
	&__content {
		background-color: #fff;
		padding-top: 6.6rem;
		@include g.mq {
			padding-top: 0;
			background-color: transparent;
		}
	}
	&__image {
		@include g.mq {
			margin-right: calc( ((100vw - 41.4rem) / 2) * -1);
			margin-left: calc( ((100vw - 41.4rem) / 2) * -1);
			margin-bottom: 3.3rem;
		}
		@include g.mq(sm) {
			margin-right: -10%;
			margin-left: -10%;
			margin-bottom: 2rem;
		}
	}
	&__list {
		display: flex;
		padding: 3.6rem 0;
		@include g.mq {
			flex-direction: column;
			background-color: #fff;
			padding: 3.6rem 5% 2.3rem;
		}
	}
	&__item {
		@include g.mq {
		}
		&:nth-of-type(1) {
			padding-right: 6.3%;
			@include g.mq {
				padding: 0 0 3rem;
			}
		}
		&:nth-of-type(2) {
			padding: 0 6.3%;
			@include g.mq {
				padding: 3rem 0;
			}
		}
		&:nth-of-type(3) {
			padding-left: 6.3%;
			@include g.mq {
				padding: 3rem 0;
			}
		}
		&:not(:last-of-type) {
			border-right: 1px solid #707070;
			@include g.mq {
				border-right: none;
				border-bottom: 1px solid #707070;
			}
		}
		&__inner {
			max-width: 22rem;
			@include g.mq {
				max-width: none;
			}
		}
		&__title {
			font-size: 2.8rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 3.3rem;
			@include g.mq {
				font-size: 2.4rem;
				margin-bottom: 2rem;
			}
		}
	}
}
.application-flow {
	&__content {
		position: relative;
		padding: 6.5rem 0;
		border: 1px solid g.$main-text-color;
		@include g.mq {
			padding: 4rem 0;
		}
		&__inner {
			width: 81.7%;
			margin: 0 auto;
		}
		&__title {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 2px solid g.$main-text-color;
			border-radius: 25px;
			font-size: 1.4rem;
			font-weight: bold;
			text-align: center;
			width: 15rem;
			height: 25px;
			line-height: 25px;
			background-color: #fff;
		}
	}
	&__list {
		display: flex;
		justify-content: space-between;
		@include g.mq {
			flex-direction: column;
		}
	}
	&__item {
		width: 19.1%;
		position: relative;
		@include g.mq {
			width: 100%;
		}
		&:not(:last-of-type) {
			@include g.mq {
				margin-bottom: 3rem;
			}
			&::after {
				display: inline-block;
				position: absolute;
				top: 55%;
				right: -26%;
				content: "";
				width: 19.5px;
				height: 19.5px;
				background: url(../images/america/icon_arrow-triangle.svg) no-repeat center center/contain;
				@include g.mq {
					display: none;
				}
			}
		}
		&__index {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.2rem;
			font-weight: bold;
			background-color: rgba(20, 146, 230, 0.13);
			border-radius: 45px;
			height: 4.5rem;
			margin-bottom: 1.5rem;
			@include g.mq {
				height: 2.5rem;
			}
			span {
				position: relative;
				padding-right: 2.2rem;
				&::after {
					@include g.mq {
						display: inline-block;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-65%) rotate(90deg);
						content: "";
						width: 12.9px;
						height: 13px;
						background: url(../images/america/icon_arrow-triangle.svg) no-repeat center top/contain;
					}
				}
			}
		}
		&__content {
			background-color: g.$bg-color;
			padding: 2.8rem 0 2.5rem;
			@include g.mq {
				display: flex;
				align-items: center;
				padding: 1.5rem;
			}
		}
		&__title {
			font-size: 1.8rem;
			font-weight: bold;
			text-align: center;
			line-height: 1;
			margin-bottom: 1.2rem;
			white-space: nowrap;
			@include g.mq {
				margin-bottom: 0.5rem;
				text-align: left;
			}
		}
		&__icon {
			text-align: center;
			margin-bottom: 1.1rem;
			@include g.mq {
				width: 21.6%;
				margin-bottom: 0;
				margin-right: 1rem;
				flex-shrink: 0;
				img {
					height: 5.5rem;
				}
			}
			
		}
		&__text {
			font-size: 1rem;
			line-height: 1.7;
			width: 70.5%;
			margin: 0 auto;
			white-space: break-spaces;
			@include g.mq {
				width: 100%;
			}
		}
	}
}
.review {
	background-color: g.$bg-color;
	&__copy,
	&__detail {
		background-color: #fff;
	}
	&__copy {
		padding: 11.1rem 4.5rem 4rem;
		margin-bottom: 7rem;
		@include g.mq {
			padding: 4.7rem 6% 5rem;
			margin-bottom: 4rem;
		}
		&__title {
			font-size: 7.2rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 3.3rem;
			@include g.under-color(0, 42px, #FFFF98);
			@include g.mq {
				font-size: 3.6rem;
				margin-bottom: 2rem;
				@include g.under-color(2px, 18px, #FFFF98);
			}
		}
		&__button {
			@include g.mq {
				margin-right: auto;
			}
		}
	}
	&__detail {
		width: 46.8%;
		padding: 7.4rem 4.7% 7.9rem;
		display: flex;
		flex-direction: column;
		@include g.mq {
			width: 100%;
			padding: 4rem 6%;
		}
		&--left {
			@include g.mq {
				margin-bottom: 4rem;
			}
		}
		&__wrap {
			display: flex;
			justify-content: space-between;
			@include g.mq {
				flex-direction: column;
			}
		}
		&__title {
			font-size: 2.8rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 1.6rem;
			@include g.under-color(0, 21px, #FFFF98);
			@include g.mq(lg) {
				font-size: 2.2rem;
			}
		}
		&__list {
			max-width: 28rem;
			margin: 0 auto 1.1rem;
		}
		&__item {
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 2.22;
			@include g.under-color(10px, 12px, #FFFF98);
			@include g.mq {
				font-size: 1.6rem;
			}
		}
		&__note {
			font-size: 1.2rem;
			font-weight: bold;
			text-align: right;
			margin-bottom: 1.3rem;
		}
		&__text {
			font-size: 1.4rem;
			line-height: 2.28;
			padding-top: 0.7rem;
			margin-bottom: 1.8rem;
		}
		&__button {
			margin-top: auto;
			@include g.mq {
				margin-right: auto;
			}
		}
	}
}