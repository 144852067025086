@use "../global" as g;

/* ==========================================================================
    header
========================================================================== */
.header {
	background-color: transparent;
	position: relative;
	z-index: 100;
	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 8rem;
		padding: 1.8rem 0;
	}
	&__logo {
		align-self: flex-start;
		width: 21.9rem;
		@include g.mq(sm) {
			width: 17rem;
			padding-top: 0.5rem;
		}
	}
	&__nav {
		margin-left: auto;
		@include g.mq {
			z-index: 200;
			position: absolute;
			top: 0;
			right: -30rem;
			width: 30rem;
			height: 100vh;
			margin-right: 0;
			padding: 2.4rem;
			background-color: #333;
			transition: right 0.3s;
		}
		&__logo {
			display: none;
			@include g.mq {
				display: block;
				width: 12.9rem;
				margin: 0.6rem 0 3.2rem;
			}
		}
		&__list {
			&._for-pc {
				display: flex;
				@include g.mq(xl) {
					display: none;
				}
			}
			&._for-tab {
				display: none;
				@include g.mq(xl) {
					display: flex;
				}
				@include g.mq {
					display: none;
				}
			}
			&._for-sp {
				display: none;
				@include g.mq {
					display: flex;
				}
			}
			@include g.mq {
				flex-direction: column;
				margin-bottom: 3.2rem;
			}
		}
		&__item {
			@include g.mq {
				color: #fff;
				font-size: 2rem;
			}
			&:not(:last-of-type) {
				margin-right: 3.2rem;
				@include g.mq {
					margin-right: 0;
					margin-bottom: 1.5rem;
				}
			}
			&:last-of-type {
				@include g.mq {
					padding-bottom: 5rem;
					margin-bottom: 5rem;
				}
			}
		}
		&__bottom {
			display: none;
			@include g.mq {
				display: flex;
			}
		}
		&__icon {
			display: block;
			height: 3.5rem;
			img {
				height: 100%;
			}
		}
		&__button {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			cursor: pointer;
			font-weight: 700;
			font-size: 14px;
			line-height: 20px;
			color: #fff;
			border-radius: 4px;
			&--start-now {
				background-color: #e85c41;
				width: 9.7rem;
				margin-right: 2.4rem;
			}
			&--login {
				width: 7.5rem;
				border: 1px solid #fff;
			}
		}
	}
	&__dropdown {
		position: relative;
		&__title {
			cursor: pointer;
			@include g.mq {
				display: flex;
				justify-content: space-between;
			}
		}
		&__arrow {
			margin-left: 1rem;
			flex-shrink: 0;
			&.is-rotated img {
				transform: rotate(180deg);
			}
			img {
				vertical-align: middle;
			}
		}
		&__list {
			position: absolute;
			top: 3rem;
			background-color: #fff;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
			z-index: 1;
			border-radius: 4px;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s;
			@include g.mq(xl) {
				top: 4rem;
				right: -50%;
			}
			@include g.mq {
				position: static;
				visibility: visible;
				opacity: 1;
				display: none;
				transition: none;
				background-color: transparent;
				box-shadow: none;
				width: auto;
			}
			&--info {
				@include g.mq(xl) {
					width: 23rem;
				}
			}
			&--price {
				@include g.mq(xl) {
					width: 10rem;
				}
			}
			&--countries {
				width: 18rem;
			}
		}
		&__link {
			font-size: 1.4rem;
			display: block;
			padding: 0.5rem 1.5rem;
			@include g.mq {
				font-size: 2rem;
				color: #fff;
			}
			&:hover {
				background-color: #ebebeb;
			}
			&:first-of-type {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}
			&:last-of-type {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
	&__sub-menu {
		position: relative;
		padding: 0.5rem 1.5rem;
		&__header {
			cursor: pointer;
		}
		&__title {
			font-size: 1.4rem;
			@include g.mq {
				font-size: 2rem;
			}
		}
		&__arrow {
			margin-left: 0.5rem;
			&.is-rotated img {
				transform: rotate(180deg);
			}
			img {
				vertical-align: middle;
			}
		}
		&__list {
			position: absolute;
			top: 0;
			left: 100%;
			background-color: #f5f5f5;
			width: 16rem;
			border-radius: 4px;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s;
			@include g.mq {
				position: static;
				visibility: visible;
				opacity: 1;
				display: none;
				transition: none;
				background-color: transparent;
				width: 23rem;
				box-shadow: none;
			}
		}
		&__link {
			font-size: 1.4rem;
			display: block;
			padding: 0.5rem 1rem;
			@include g.mq {
				font-size: 1.8rem;
			}
			&:hover {
				background-color: #ebebeb;
			}
			&:first-of-type {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}
			&:last-of-type {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
	&__login {
		width: 7.6rem;
		height: 40px;
		min-width: 7.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #0e3c48;
		border-radius: 4px;
		margin-left: 3.2rem;
		@include g.mq {
			margin-left: 0;
			margin-right: 4rem;
		}
	}
	&__toggle {
		display: none;
		@include g.mq {
			position: absolute;
			top: 2.8rem;
			right: 5%;
			display: block;
			width: 28px;
			height: 24px;
			cursor: pointer;
			z-index: 300;
			span {
				display: block;
				position: relative;
				background-color: g.$main-text-color;
				height: 2px;
				border-radius: 1px;
				transition: all 0.3s;
				&::before,
				&::after {
					position: absolute;
					content: "";
					width: 100%;
					height: 2px;
					border-radius: 1px;
					background-color: g.$main-text-color;
					transition: all 0.3s;
				}
				&::before {
					top: -11px;
				}
				&::after {
					top: 11px;
				}
			}
		}
	}
}

/* メニューオープン時 */
.is-open {
	height: 100%;
  overflow-y: hidden;
	.header__logo {
		display: none;
	}
	.header__nav {
		right: 0;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		&__overlay {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
			background: rgba(51, 51, 51, 0.64);
			width: 100%;
			height: 100vh;
		}
	}
	.header__toggle span {
		background-color: transparent;
		&::before,
		&::after {
			background-color: #fff;
		}
		&::before {
			top: 0;
			transform: rotate(45deg);
		}
		&::after {
			top: 0;
			transform: rotate(-45deg);
		}
	}
}
.scroll-prevent {
	position: fixed;
	width: 100%;
}

/* ヘッダー パターン別 */
.header {
	// テキスト白 
	&._text-white {
		.header__nav {
			&__item {
				color: #fff;
			}
			&__link {
				&:hover {
					color: g.$link-color;
				}
			}
		}
		.header__login {
			color: #fff;
			border-color: #fff;
			&:hover {
				opacity: 0.8;
			}
		}
		.header__dropdown {
			&__item {
				color: g.$main-text-color;
			}
		}
		.header__sub-menu {
			&__title {
				@include g.mq {
					color: #fff;
				}
			}
			&__link {
				@include g.mq {
					color: #fff;
					&:hover {
						color: g.$link-color;
					}
				}
			}
		}
		.header__toggle {
			span {
				background-color: #fff;
				&::before,
				&::after {
					background-color: #fff;
				}
			}
		}
		.is-open & {
			.header__toggle span {
				background-color: transparent;
			}
		}
	}
	// 背景 白 
	&._bg-white {
		background-color: #fff;
	}
	// 背景 黒 
	&._bg-black {
		background-color: #000;
	}
	// 下線有り
	&._bd-bottom {
		border-bottom: 1px solid #ccc;
	}
}