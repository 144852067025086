@use "../global" as g;

/* ==========================================================================
    フォーム
========================================================================== */

/* トップページで使用しているチャットフォーム */
.first-form {
	height: 31.3rem;
	padding: 1.6rem;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
	@include g.mq {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		height: 100%;
	}
	&__wrap {
		position: absolute;
		right: 0;
		bottom: 14px;
		width: 27%;
		min-width: 292px;
		@include g.mq {
			width: 100%;
			height: 56px;
		}
	}
	&__header {
		display: none;
	}
	&__fields {
		position: relative;
		flex: 1;
		height: 80%;
		margin-bottom: 1rem;
		@include g.mq {
			height: 100%;
			margin-bottom: 0;
			padding-right: 1rem;
		}
	}
	&__textarea {
		width: 100%;
		height: 100%;
		@include g.mq {
			padding: 0.5rem;
		}
	}
	&__placeholder {
		position: absolute;
		top: 0;
		left: 0;
		color: gray;
		@include g.mq {
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&__button {
		width: 100%;
		padding: 1.2rem 2.4rem;
		border-radius: 4px;
		background-color: #483519;
		color: #fff;
		font-weight: 700;
		text-align: center;
		cursor: pointer;
		@include g.mq {
			padding: 0.8rem 1.6rem;
			font-size: 1.4rem;
		}
	}
}
.ityped-cursor {
	font-size: 1em;
	opacity: 1;
	-webkit-animation: blink 0.3s infinite;
	-moz-animation: blink 0.3s infinite;
	animation: blink 0.3s infinite;
	animation-direction: alternate;
}
@keyframes blink {
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes blink {
	100% {
		opacity: 0;
	}
}
@-moz-keyframes blink {
	100% {
		opacity: 0;
	}
}

/* フォーム オープン時 */
.is-form-open {
	height: 100%;
  overflow-y: hidden;
	.first-form {
		position: absolute;
		flex-direction: column;
		padding: 1.5rem;
		z-index: 200;
		width: 100%;
		&__wrap {
			height: 100%;
			z-index: 200;
		}
		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 1rem;
			&__close-button {
				cursor: pointer;
			}
		}
		&__fields {
			 padding-right: 0;
			 margin-bottom: 1rem;
			 width: 100%;
		}
		&__submit {
			align-self: flex-end;
		}
		&__overlay {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;
			background: rgba(51, 51, 51, 0.64);
			width: 100%;
			height: 100vh;
		}
	} 
}

/* page__for-expertsで使用しているチャットフォーム */
.second-form {
	border-radius: 4px;
	box-shadow: 0 0 10px 0 rgba(51, 51, 51, .6);
	background: #fff;
	padding: 1.6rem;
	&__wrap {
		width: 35%;
		margin-top: 1.6rem;
		max-width: 50rem;
		min-width: 41.8rem;
		z-index: 10;
		@include g.mq(lg) {
			width: 100%;
			margin-top: 3.2rem;
		}
		@include g.mq(sm) {
			min-width: 30rem;
		}
	}
	&__title {
		font-size: 2rem;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 1.6rem;
	}
	&__fields {
		margin-bottom: 1.6rem;
	}
	&__group {
		&:not(:last-of-type) {
			margin-bottom: 1.6rem;
		}
		&--name {
			display: flex;
			justify-content: space-between;
		}
	}
	&__box {
		width: 48%;
	}
	&__label {
		display: block;
		font-size: 1.4rem;
		line-height: 1.43;
		color: #666;
		margin-bottom: 0.8rem;
	}
	&__control {
		width: 100%;
		border: 1px solid #999;
		border-radius: 4px;
		padding: 8px;
		transition: border .2s ease-in-out, box-shadow .2s ease-in-out;
		font-size: 1.4rem;
		height: 4rem;
		background: #fff;
		&::placeholder {
			color: #999;
		}
		&:hover {
			border: 1px solid #52bad5
		}
		&:focus {
			border: 1px solid #52bad5;
			box-shadow: 0 0 5px 0 rgba(82, 186, 213, .5);
		}
		&__wrap {
			position: relative;
			&::after {
				top: 50%;
				transform: translateY(-50%);
				right: 8px;
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 8px solid #333;
				user-select: none;
			}
		}
		&--select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			&::-ms-expand {
				display: none;
			}
		}
		&.texttype {
			height: 12rem;
		}
	}
	&__cta {
		display: flex;
		align-items: center;
		&__text {
			color: #999;
			font-family: Helvetica;
			font-size: 1rem;
			line-height: 1.4;
			vertical-align: top;
			@include g.mq(sm) {
				line-height: 1.2;
			}
			a {
				color: #52bad5;
				&:hover {
					color: #30a6c5;
					text-decoration: underline;
				}
			}
		}
	}
	&__button {
		border-radius: 4px;
		display: inline-block;
		background: #e85c41;
		padding: 1.2rem 2.4rem;
		margin-right: 1rem;
		transition: background-color .1s ease-in-out, color .1s ease-in-out, box-shadow .1s ease-in-out;
		cursor: pointer;
		font-weight: 700;
		-webkit-appearance: none;
		color: #fff;
		width: 8rem;
		flex-shrink: 0;
		&:hover {
			background: #d5391a;
		}
		&:focus {
			background: #9d230b
		}
		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
	&__confirm-text {
		margin-bottom: 2rem;
		display: none;
	}
	div[class^="second-form__error"] {
		color: red;
		font-size: 1.2rem;
		margin-top: 0.5rem;
		padding-left: 0.5rem;
	}
}

/* first-formを挿入した場合 */
.include-first-form {
	@include g.mq {
		.first-form {
			flex-direction: column;
			align-items: normal;
			height: 31.3rem;
			&__placeholder {
				top: 5%;
			}
		}
	}
}

/* second-formを挿入した場合 */
.include-second-form {
	@include g.mq {
		.first-form__wrap {
			height: 100%;
		}
		.second-form {
			margin-top: 23rem;
		}
	}
}