@use "../global" as g;

/* ==========================================================================
    footer
========================================================================== */
.footer {
	background-color: g.$bg-color;
	padding: 4rem 0 4.8rem;
	@include g.mq {
		background-color: transparent;
		padding: 4rem 0 3.2rem;
		border-top: 1px solid #ccc;
	}
	&._bd-top {
		border-top: 1px solid #ccc;
	}
	.container {
		@include g.mq {
			max-width: 41.4rem;
		}
	}
	&__nav {
		display: flex;
		justify-content: space-between;
		margin-bottom: 3.2rem;
		@include g.mq {
			flex-direction: column;
		}
	}
	&__column {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@include g.mq {
			padding: 1.2rem 0;
			border-bottom: 1px solid #ccc;
		}
		&__header {
			line-height: 2;
			font-weight: 700;
			display: flex;
			align-items: center;
			@include g.mq {
				color: #4d4d4d;
				width: 100%;
				cursor: pointer;
			}
		}
		&__arrow {
			display: none;
			margin-left: auto;
			@include g.mq {
				display: block;
			}
			&.is-rotated img {
				transform: rotate(180deg);
			}
			img {
				vertical-align: middle;
			}
		}
		&__list {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			@include g.mq {
				padding: 1.6rem 0 0 2.4rem;
				display: none;
			}
		}
		&__link {
			font-size: 1.4rem;
			line-height: 2.3;
			color: #666;
			@include g.mq {
				font-size: 1.6rem;
				line-height: 2;
			}
			&:not(.footer__sub-menu) {
				&:hover {
					color: g.$link-color;
				}
			}
		}
	}
	&__sub-menu {
		position: relative;
		&__header {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		&__title {
			font-size: 1.4rem;
			line-height: 2.3;
			color: #666;
			@include g.mq {
				font-size: 1.6rem;
				line-height: 2;
			}
		}
		&__arrow {
			margin-left: 0.8rem;
			&.is-rotated img {
				transform: rotate(180deg);
			}
			img {
				vertical-align: middle;
			}
		}
		&__list {
			margin-left: 0.8rem;
			border-radius: 4px;
			position: absolute;
			background: #fff;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
			left: 100%;
			top: 0;
			z-index: 1;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s;
			@include g.mq {
				display: none;
				visibility: visible;
				opacity: 1;
				padding-left: 24px;
				position: static;
				box-shadow: none;
			}
		}
		&__link {
			padding: 0.8rem 1.6rem;
			font-size: 1.4rem;
			line-height: 20px;
			letter-spacing: 0.07px;
			display: block;
			white-space: nowrap;
			color: #333;
			@include g.mq {
				color: #666;
			}
			&:hover {
				background-color: #ebebeb;
			}
		}
	}
	&__utility {
		padding-top: 3.2rem;
		border-top: 1px solid #ccc;
		display: flex;
		align-items: center;
		@include g.mq {
			padding-top: 0;
			border-top: none;
			flex-direction: column;
		}
	}
	&__social {
		order: 2;
		margin-left: auto;
		@include g.mq {
			margin-left: 0;
			margin-bottom: 3.2rem;
			width: 100%;
			order: 1;
		}
		&__inner {
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			@include g.mq {
				flex-wrap: wrap;
				max-width: 30rem;
			}
		}
		&__icon {
			@include g.mq {
				order: 2;
			}
			margin-right: 2rem;
			&__link {
				&:not(:last-of-type) {
					margin-right: 1.5rem;
				}
			}
		}
		&__logo {
			display: flex;
			align-items: center;
			@include g.mq {
				order: 1;
				margin-bottom: 2rem;
			}
			&__link {
				display: block;
				&--dmca {
					width: 15.5rem;
					margin-right: 1.5rem;
					@include g.mq {
						order: 2;
					}
				}
				&--norton {
					width: 10rem;
					@include g.mq {
						order: 1;
						margin-right: 2rem;
					}
				}
			}
		}
	}
	&__legal {
		order: 1;
		font-size: 1.2rem;
		line-height: 1.33;
		color: #666;
		margin-right: 1rem;
		flex: 1;
		@include g.mq {
			padding-top: 32px;
			border-top: 1px solid #ccc;
			width: 100%;
			order: 2;
		}
		&__inner {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		&__copyright {
			margin-bottom: 0.5rem;
		}
		&__link {
			&:not(:last-of-type) {
				margin-right: 0.8rem;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

// .page__how-it-works,
// .page__for-experts,
// .page__for-question,
// .page__common,
// .page__usage-flow,
// .page__service,
// .page__price  {
// 	.footer {
// 		@include g.mq {
// 			padding-top: 4rem;
// 		}
// 		@include g.mq(sm) {
// 			padding-top: 2rem;
// 		}
// 	}
// }
// .page__for-experts,
// .page__for-question,
// .page__common,
// .page__usage-flow,
// .page__service,
// .page__price {
// 	.footer {
// 		@include g.mq {
// 			border-top: 1px solid #ccc;
// 		}
// 	}
// }
// .page__how-it-works {
// 	.footer {
// 		border-top: 1px solid #ccc;
// 		@include g.mq {
// 			border-top: none;
// 		}
// 	}
// }