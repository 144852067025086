@use "../global" as g;

/* ==========================================================================
		utility
========================================================================== */
.xl-show,
.xl-lg-show,
.xl-md-show,
.xl-sm-show,
.xl-xs-show,

.lg-show, 
.lg-md-show,
.lg-sm-show,
.lg-xs-show,

.md-show,
.md-sm-show,
.md-xs-show,

.sm-show,
.sm-xs-show,

.xs-show {
  display: none;
}

@media screen and (max-width: 1279px) {
  .xxl-show {
    display: none;
  }
  .xl-show,
  .xl-lg-show,
  .xl-md-show,
  .xl-sm-show,
  .xl-xs-show {
    display: block;
  }
}
@media screen and (max-width: 979px) {
  .xxl-xl-show,
  .xl-show {
    display: none;
  }
  .lg-show, 
  .lg-md-show,
  .lg-sm-show,
  .lg-xs-show {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .xxl-lg-show,
  .xl-lg-show,
  .lg-show {
    display: none;
  }
  .md-show,
  .md-sm-show,
  .md-xs-show {
    display: block;
  }
}
@media screen and (max-width: 479px) {
  .xxl-md-show,
  .xl-md-show,
  .lg-md-show, 
  .md-show {
    display: none;
	}
  .sm-show,
  .sm-xs-show {
    display: block;
  }
}
@media screen and (max-width: 359px) {
  .xxl-sm-show,
  .xl-sm-show,
  .lg-sm-show,
  .md-sm-show,
  .sm-show {
    display: none;
  }
  .xs-show {
    display: block;
  }
}