@use "../global" as g;

/* ==========================================================================
    汎用ページ
========================================================================== */
.page__generic {
	.page__container {
    position: relative;
		max-width: 92rem;
	}
	.page__header,
	.page__body {
		color: #333;
	}
	.page__content {
		&__title {
			font-size: 2.4rem;
			font-weight: 700;
			margin: 3.2rem 0 0.8rem;
			@include g.mq {
				font-size: 2.2rem;
			}
			@include g.mq(sm) {
				font-size: 2rem;
			}
		}
		ul {
			margin-left: 5.2%;
			list-style: disc;
		}
		p, li {
			@include g.mq {
				font-size: 1.4rem;
			}
			&:not(:last-of-type) {
				margin-bottom: 1.6rem;
				@include g.mq(sm) {
					margin-bottom: 1.4rem;
				}
			}
		}
	}
}