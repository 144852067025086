@use "../global" as g;

/* ==========================================================================
   overview
========================================================================== */

.what-is-ns {
	padding-top: 0;
}
.what-is-ns__detail {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:not(:last-of-type) {
		margin-bottom: 9rem;
		@include g.mq {
			border-bottom: 1px solid #ccc;
			margin-bottom: 6rem;
			padding-bottom: 6rem;
		}
		@include g.mq(sm) {
			margin-bottom: 4rem;
			padding-bottom: 4rem;
		}
	}
	&--01,
	&--03 {
		@include g.mq {
			flex-direction: column-reverse;
		}
	}
	&--02 {
		flex-direction: row-reverse;
		@include g.mq {
			flex-direction: column-reverse;
		}
	}
	&__content {
		width: 39.2%;
		min-width: 32rem;
		@include g.mq {
			width: 100%;
		}
	}
	&__image {
		width: 52.5%;
		@include g.mq {
			width: 90%;
			margin-bottom: 4rem;
		}
		@include g.mq(sm) {
			width: 100%;
		}
	}
	&__title {
		margin-bottom: 2.4rem;
		@include g.mq {
			margin-bottom: 2rem;
			text-align: center;
		}
	}
	&__text {
		font-size: 2rem;
		line-height: 1.6;
		letter-spacing: 0.15px;
		@include g.mq(lg) {
			font-size: 1.8rem;
			line-height: 1.66;
		}
	}
	&__slide {
		.swiper-pagination-bullet {
			width: 16px;
			height: 16px;
			margin: 4px;
			cursor: pointer;
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 50%;
			display: inline-block;
			transition: background-color 0.6s;
			opacity: 1;
			&-active,
			&:hover {
				background-color: g.$link-color;
				border-color: g.$link-color;
			}
		}
		.swiper-horizontal>.swiper-pagination-bullets,
		.swiper-pagination-bullets.swiper-pagination-horizontal,
		.swiper-pagination-custom,
		.swiper-pagination-fraction {
			bottom: 5%;
		}
	}
	&__item {
		img {
			width: 100%;
		}
	}
}
.what-is-ns__chat {
	height: 38.6rem;
	letter-spacing: -0.5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&__box {
		&--left {
			display: flex;
			align-items: flex-end;
		}
		&--right {
			align-self: flex-end;
		}
		&.zoomIn {
			-webkit-animation-name: zoomIn;
			animation-name: zoomIn;
			-webkit-animation-duration: 0.7s;
			animation-duration: 0.7s;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			&:nth-of-type(2) {
				animation-delay: 0.4s;
			}
			&:nth-of-type(3) {
				animation-delay: 0.6s;
			}
			&:nth-of-type(4) {
				animation-delay: 0.8s;
			}
		}
	}
	&__icon {
		width: 48px;
		height: 48px;
		margin-right: 1.6rem;
		background: url('../images/how-it-works/img_chat.png') center / contain no-repeat;
	}
	&__message {
		padding: 1.6rem 2rem;
		&--left {
			background: #f5f5f5;
			box-shadow: 0 8px 30px rgba(45, 62, 67, 0.13);
			border-radius: 34px;
			width: 35.6rem;
			@include g.mq(lg) {
				width: 26.4rem;
			}
		}
		&--right {
			background: #2aa6f8;
			color: #fff;
			box-shadow: 0 8px 13px rgba(0, 0, 0, 0.11);
			border-radius: 34px;
			width: 29.5rem;
		}
	}
}
@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}
.about-ns {
	&__title {
		margin-bottom: 4rem;
		text-align: center;
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 98rem;
		margin: 0 auto;
	}
	&__item {
		width: 30%;
		@include g.mq(lg) {
			width: 45%;
		}
		@include g.mq {
			width: 100%;
			display: flex;
		}
		&:nth-of-type(-n+3) {
			margin-bottom: 4rem;
		}
		&:nth-of-type(4) {
			@include g.mq(lg) {
				margin-bottom: 4rem;
			}
		}
		&:nth-last-of-type(2),
		&:nth-last-of-type(3) {
			@include g.mq {
				margin-bottom: 4rem;
			}
		}
		&__title {
			margin-bottom: 0.8rem;
			font-size: 2rem;
			font-weight: 700;
			letter-spacing: 0.15px;
		}
		&__icon {
			width: 4.8rem;
			height: 4.8rem;
			margin-bottom: 1.6rem;
			background: url(../images/for-overview/icon_about-ns.png) top left no-repeat;
			@include g.mq {
				margin-right: 3rem;
				flex-shrink: 0;
			}
			&--person {
				background-position: 0 0;
			}
			&--clock {
				background-position: 0 -4.8rem;
			}
			&--pig {
				background-position: 0 -9.6rem;
			}
			&--guys {
				background-position: 0 -14.4rem;
				width: 5.5rem;
			}
			&--puzzle {
				background-position: 0 -19.2rem;
			}
			&--heart {
				background-position: 0 -24rem;
			}
		}	
	}
}
